import "styles/pages/page-pricing.scss";

import React, { useState } from "react";
import { Link } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Divider from "components/Divider";
import Breadcrumps from "components/Breadcrumps";
import BookingModal from "components/BookingModal";

const PricingPage = ({ pageContext }) => {
  const seo = pageContext.seo;
  const pageData = pageContext.data.pagePricing;
  const [tab, setTab] = useState("services");
  const [bookingModal, setBookingModal] = useState(false);

  const pricingData =
    tab === "services" ? pageData?.pricing : pageData?.pricingTests;

  console.log(pageData);

  return (
    <Layout>
      <Breadcrumps data={pageContext.title} />
      <Seo title={seo.title} description={seo.metaDesc} />
      <SubpageHeader title={pageContext.data.title} />

      <section className="pricing-main">
        <div className="container">
          <div className="pricing-main__info">
            Cennik poradni psychologicznej w Warszawie i Wołominie
          </div>

          <div className="pricing-main__nav">
            <button
              onClick={() => setTab("services")}
              className={tab === "services" ? "current" : ""}
            >
              Usługi
            </button>
            <button
              onClick={() => setTab("tests")}
              className={tab === "tests" ? "current" : ""}
            >
              Testy psychologiczne
            </button>
          </div>

          {tab === "tests" && (
            <p className="pricing-main__desc">
              Przed każdym badaniem niezbędny jest wywiad diagnostyczny. Każde
              badanie może być poszerzone o dodatkowe konsultacje lub testy,
              jeśli diagnosta uzna to za konieczne.
            </p>
          )}

          {pricingData?.map((item, index) => (
            <div className="pricing-main__single-pricing" key={index}>
              <h2>{item.title}</h2>
              <Divider marginTop={15} marginBottom={20} />
              <div className="pricing-main__table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <td>Świadczenie</td>
                      <td>Stacjonarnie/Online</td>
                      <td>Czas</td>
                      <td>Cena</td>
                    </tr>
                  </thead>
                  <tbody>
                    {item.table?.map((row, index) => (
                      <tr key={index}>
                        <td>{row.table1}</td>
                        <td>{row.table2}</td>
                        <td>{row.table3}</td>
                        <td>{row.table4}</td>
                        <td>
                          <Link
                            to="/rezerwacja-wizyt/"
                            className="button button--small button--green"
                          >
                            {row.contactBtn ? "Skontaktuj się" : "Umów wiztę"}
                          </Link>
                          {row.internalUrl && (
                            <>
                              <br />
                              <Link
                                to={row.internalUrl}
                                className="button button--small button--red"
                              >
                                Sprawdź ofertę
                              </Link>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
          <div className="pricing-main__price-info">
            Podane ceny mają jedynie charakter informacyjny i nie stanowią
            oferty handlowej w rozumieniu Art. 66 par.1 Kodeksu Cywilnego.
          </div>
          <Divider marginTop={45} marginBottom={45} />
          <div className="pricing-main__methods">
            <h3>Metody płatności w naszej poradni</h3>
            <div className="pricing-main__logos">
              <img
                src={require("assets/images/visa.svg").default}
                alt=""
                className="img-fluid"
              />
              <img
                src={require("assets/images/mastercard.svg").default}
                alt=""
                className="img-fluid"
              />
              <img
                src={require("assets/images/blik.svg").default}
                alt=""
                className="img-fluid"
              />
            </div>
            <div>Akceptujemy również płatność gotówką na miejscu.</div>
          </div>
          <Divider marginTop={50} marginBottom={50} />
          <div className="pricing-main__buttons">
            <button
              onClick={() => setBookingModal(true)}
              className="button button--red"
            >
              Zadzwoń
            </button>
            <Link to="/kontakt/" className="button button--orange">
              Wyślij zapytanie
            </Link>
          </div>
        </div>
        {pageData?.pricingDescription && (
          <div className="container">
            <div
              className="pricing-main__details"
              dangerouslySetInnerHTML={{ __html: pageData.pricingDescription }}
            />
          </div>
        )}
      </section>
      {bookingModal && <BookingModal setBookingModal={setBookingModal} />}
    </Layout>
  );
};

export default PricingPage;
